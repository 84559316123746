/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    capsule: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1.828 8.9L8.9 1.827a4 4 0 115.657 5.657l-7.07 7.071A4 4 0 111.827 8.9zm9.128.771l2.893-2.893a3 3 0 10-4.243-4.242L6.713 5.429z"/>',
    },
});
